@import 'variables';

html {
   font-size: 15px;
}
body {
  font-family: "Gotham A", "Gotham B", "san-serif";
  line-height: 200%;
}
.component {
  padding: 1rem 3rem 1rem 3rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.item {
  display: grid;
  grid-template-areas: ". neighborhoods ." ". locationContainer ." ". menuContainer ."
                        ". map ."
                        "footer footer footer";
  grid-template-columns: 1fr 100% 1fr;
  font-family: "Gotham A", "Gotham B", "san-serif";
  justify-content: center;
  line-height: 200%;
  margin: 0 auto;
  width: 100%;
}
.slide-routes {
  display: block !important;
}
.nyucolumncontrol {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.nyucolumncontrol .cc_50-50_c0, .nyucolumncontrol .cc_50-50_c1 {
  width: 100%;
}
.map-container {
  position: relative;
  height:400px;
  width: 100%;
}
a {
  color: $nyupurple;
  cursor: pointer;
  text-decoration: none;
}
div {
  margin: 0;
  padding: 0;
  h1, h2, h3, h4, h5, h6, li, a {
      line-height: 125%;
      margin: 1rem;
  }
}
h3 {
  color: #000;
  font-family: "Gotham A","Gotham B", sans-serif;
  font-size: 2rem;
  font-weight: 700;
}
.navigation-bar {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-bottom: solid 2px #d6d6d6;
  .back {
    min-width: 4rem;
    font-size: 44px;
    color: #000;
    text-align: center;
    padding-bottom: 5px;
    margin: 0;
  }
  h1.page-title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin: 0 auto;
  }
  .spacer {
    min-width: 4rem;
    margin: 0;
  }
}
.leave {
  animation: slideOut 0.5s ease;
}
@keyframes fadeIn {
  0% { opacity: 0%; }  
  100% { opacity: 100%;}
}
@keyframes slideIn{
  0% { transform: translatex(100%); }  
  100% { transform: translateX(0);}
}
@media screen and (min-width:480px) {
  .slide-routes{
    grid-template-columns: 1fr 100% 1fr;
  }
}
@media screen and (min-width:690px) {
  .slide-routes {
    grid-template-columns: 1fr 99% 1fr;
  }
  .nyucolumncontrol .cc_50-50_c0, .nyucolumncontrol .cc_50-50_c1 {
    width: 50%;
  }

}
@media screen and (min-width:930px) {
  .slide-routes {
    grid-template-columns: 1fr 99% 1fr;
  }

}