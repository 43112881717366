@import 'variables';

#neighborhoods {
  padding-top: 80px;
    grid-area: neighborhoods;
    a, a:visited {
      color: $nyupurple;
      text-decoration: none;
    }
}
