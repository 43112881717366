@import 'variables';

#locationContainer {
    padding-top: 70px;
    grid-area: locationContainer;
    //animation: slideIn 1s ease;
}

.parbase {
    clear: left;
}

a.promo-button-text, a.promo-button-text:visited, button.promo-button-text, button.promo-button-text:visited {
    color: $nyupurple;
    border: 1px solid $nyupurple;
    padding: 0.733333333333333rem 1.33333333333333rem !important;
    display: inline-block;
    text-decoration: none;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-weight: 700;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 30rem;
    background: #fff;
}
p {
    color: #404040;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 200%;
}
  