@import 'variables';

.cc_33-33-33_c0, .cc_33-33-33_c1, .cc_33-33-33_c2{
	width: 100%;
}
a.promo {
    padding: 0.666666666666667rem 1rem 1rem !important;
    display: block;
    min-width: 8.666666666666667rem;
    max-width: 30rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    &.brand_color_1 {
        background: $nyupurple url("./img/promo_corner_purple.svg") no-repeat right bottom;
        background-size: 2.666666666666667rem auto;
        border: none;
        &:hover {
          background: $nyupurple url("./img/promo_corner_white.svg") no-repeat right bottom;
          background-size: 2.666666666666667rem auto;
      }
        .promo-title, .promo-content, .promo-content p {
            color: #fff;
        }
        .promo-title {
            padding: 0 0.33333333333333rem 0.33333333333333rem 0;
            margin: 0 !important;
            font-size: 1.2rem;
            line-height: 150%;
        }
    }
    &:not(.brand_color_1) {
      background: #fff url("./img/promo_corner_white.svg") no-repeat right bottom;
      background-size: 2.666666666666667rem auto;
      padding-bottom: 0.2666666666666667rem;
      -webkit-box-shadow: inset 0 -0.2rem 0 0 $nyupurple;
      box-shadow: inset 0 -0.2rem 0 0 $nyupurple;
      border: 1px solid #d6d6d6;
      &:hover {
        background: #fff url("./img/promo_corner_purple.svg") no-repeat right bottom;
        background-size: auto;
        background-size: 2.666666666666667rem auto;
      }

      .promo-title {
        color: $nyupurple;
        padding: 0 0.33333333333333rem 0.33333333333333rem 0;
        margin: 0 !important;
        font-size: 1.2rem;
        line-height: 150%;
      }
    }
}


h6 {
  color: #404040;
  font-family: "Gotham SSm A","Gotham SSm B", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
